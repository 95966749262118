import {  useNavigate  } from 'react-router-dom';
import styled from 'styled-components';
import toonflixlogo from "../../assets/images/toonflix.png";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { GetRequest } from '../../utils/utils';
import ClipLoader from "react-spinners/ClipLoader"

const SuccessPage = () => {
  const [ loader, setLoader ] = useState(false);
  var  q = new URLSearchParams(window.location.search);
  const nav = useNavigate();
  
  // useEffect(() => {
    const checkSubStatus = async () => {

      const msisdn = q.get("msisdn") || localStorage.getItem("iq_toonflix_msisdn");
      const ext_ref = q.get("ext_ref") || localStorage.getItem("iq_toonflix_ext_ref");
      const uuid = q.get("uuid") || localStorage.getItem("iq_toonflix_uuid");

        let query = `msisdn=${
          msisdn
        }&ext_ref=${
          ext_ref
        }&uuid=${
          uuid
        }`;

        setLoader(true);
        const { ex, data } = await GetRequest({action: 'check-sub', query });
        // const data = await resp.data;
        setLoader(false);
        if(ex) {
          toast.error("failed to process your request");
          return;
        }
        // console.log(data, "==== Success Page ====", "CHECK SUB API");

        if(data) {
          const i = data.result === 0 ? "error" : "info";
          toast[i](data.msg);
          if(data.result == 1) {
            Cookies.set(
              "iq-toonflix-msisdn", msisdn,
              { expires : 1 }
            );

            nav("/redirect");
            return;
          } else {
            // nav("/");
            return;
          }
      };
  };


  return (

    <React.Fragment>
    <StyledPacks>
        <div style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop : "-200px"
        }} >

            <img src={toonflixlogo} alt="Tonnflix" style={{
                width: "250px",
                marginBottom: "20px",
            }}/>
            <div> 
            <h3>
            {"شكراً لاشتراكك في خدمة Toonflix يمكنك الأن الحصول على المحتوى أو تنزيل التطبيق"}
              {/* Thank you for subscribing to Toonflix service.You can now access the content or download the APP */}
            </h3>
            </div>
                <button
                disabled={loader}
                className='link'
                onClick={checkSubStatus}
                >{
                    loader ? (
                      <ClipLoader
                      size={20}
                      color="white"
                      />
                    )
                  : "يكمل"
                 }</button>
        </div>
    </StyledPacks>
    </React.Fragment>
  )
}


export default SuccessPage;


const StyledPacks = styled.div`
/* position: absolute; */
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
/* margin: 25px auto; */
background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
 
h3{
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    direction:rtl;
    // font-weight: 300;
}
.select-here {
    width: 300px;
    color: #6c5b7c;
    text-align: center;
    margin-bottom: 10px;
}
.link{
    direction: rtl;
    text-align: center;
    color: white;
    border: 0;
    background-color: #c06c84;
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    cursor: pointer;
}
.link:hover{
    background-color: black;
}
@media screen and (max-width: 600px){
    h3 {
        text-align: center;
        font-size: 9px;
    }
}

`;