import React, { useEffect } from "react";
import {  useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
  let location = useLocation();

  useEffect(() => {
    return () => window.scrollTo({ top: 0});
    
  }, [location]);

  // const checkMsisdn

  return (
    <div>
      <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      {
        location.pathname.includes("/redirect") || 
        location.pathname.includes("/video") ? <Header /> : null
      }
      <div className="content">{props.children}</div>
      {/* {!location.pathname.includes("playgames") ? <Footer /> : null} */}
    </div>
  );
};

export default Layout;
