import React, {  useRef, useState, } from "react";
import styled from "styled-components";

import { BsPhone } from "react-icons/bs";
import toonflixlogo from "../../assets/images/toonflix.png";
import ClipLoader from "react-spinners/ClipLoader"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetRequest, PostRequest, QueryParams } from "../../utils/utils";
import Cookie from "js-cookie";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const nav = useNavigate();
  const aniRef = useRef(null);
  
  async function otp(e) {
    e.preventDefault()

    const ext_ref = QueryParams().get("ext_ref");

    const ani = await aniRef.current.value;

      let msisdn = "";
      //country-code-prefiix
      if (
        ani.charAt(0) === "9" &&
        ani.charAt(1) === "6" &&
        ani.charAt(2) === "4" 
      ) {
        msisdn = ani;
      } else {
        msisdn = "964"+ ani;
      }

      if(msisdn.length <= 5 || msisdn.length > 15) {
        toast.info("Enter A valid Number");
        return;
      }
      localStorage.setItem('iq_toonflix_msisdn', msisdn);
      const subscription = {
        msisdn,
        ext_ref,
      }

      setLoader(true);
      const { ex, data } = await PostRequest({action: 'otp', subscription });
      setLoader(false);
      console.log(data, "=== OTP DATA ===");
      if(ex) {
        // console.log(error);
        toast.error("Internal Server Error");
        return;
      };
      if(data) {
        const i = data.result === 0 ? "error" : "info";
        toast[i](data.msg);
        if(data.result === 2) {
            Cookie.set("iq-toonflix-msisdn", data.msisdn, { expires: 2 });
            nav("/redirect");
        }
        if(data.result === 1) {
          localStorage.setItem("iq_toonflix_ext_ref", data.ext_ref);
          localStorage.setItem("iq_toonflix_uuid", data.uuid);

          nav(`/confirm?msisdn=${msisdn}&ext_ref=${data.ext_ref}&uuid=${data.uuid}`);
        } else {
          // nav("/");
          return;
        };
      };
      return;
  }
  // console.log("loader ", loader);
  return (  
    <HomeContainer>
        <Box>
            <ImageContainer>
                <img src={toonflixlogo}  alt="ToonFlix"/>
            </ImageContainer>
            <Heading>
                {"إشترك الآن في خدمة Toonflix"}
            </Heading>
            <Heading>
                {"أدخل رقم هاتفك المحمول"}
            </Heading>
            <InputBox>
                <BsPhone
                 size={25}
                 color="#4ca1af"
                 />
                <input 
                 ref={aniRef}
                 type="number"
                 placeholder="+964..."
                 max={13} 
                 min={7}
                 />
            </InputBox>
            <Button 
            disabled={loader}
            onClick={otp} >
                {
                    loader ? (
                     <ClipLoader
                     size={20}
                     color="white"
                     />
                    ) : "إشترك"
                }
            </Button>
            <ServiceInfo>
                 {"كلفة الإشتراك لمشتركي زين240 دينار للرسالة المستلمة. يمكنك إلغاء الإشتراك بإرسال T2 إلى 3368 عند اشتراكك في خدمة ToonFlix تحصل على يوم مجانا"}
            </ServiceInfo>
        </Box>
    </HomeContainer>
  );
};

export default Login;

const HomeContainer = styled.div`

  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  /* background-color: #fff; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  backdrop-filter: rgba(0,0,0,0.1);
  background-blend-mode: overlay;
  box-sizing: border-box;
  padding: 25px;
  direction: rtl;
  @media screen and (max-width: 768px) {
      padding: 25px 0 0;
  }
`;

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    flex-direction: column;
    /* gap: 25px;
     */
    /* background-color: whitesmoke; */
`;

const ImageContainer = styled.div`
    margin-bottom: 15px;
    img {
        width: 400px;
        height: 70px;
    }
    @media screen and (max-width: 768px) {    
        img {
            width: 300px;
        }
    }
`;

const Heading = styled.h1`
    color: #fff;
    /* margin-top: 2px; */
    
    font-weight: 800;
    @media screen and (max-width: 768px) {    
        font-size: 18px;
    }
`;

const InputBox = styled.div`
    direction: rtl;
    width: 500px;
    height: 40px;
    display: flex;
    background-color: whitesmoke;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    input {
        border-radius: 4px;
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        padding: 10px;
        font-weight: 500;
        color: gray;
    }

    @media screen and (max-width: 768px) {
        width: 300px;
        input {
            width: 100%;
        }
    }
`;

const Button = styled.button`
    margin: 20px 0 20px;
    width: 300px;
    padding: 6px;
    color: white;
    background-color: #007991;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
`;

const ServiceInfo = styled.p`
    background-color: whitesmoke;
    padding: 10px;
    border-radius: 9px;
    align-items: center;
    
    text-align: center;
    color: gray;
    font-size: 1rem;
    font-weight: 800;

`;