import React, {  useState, } from "react";
import styled from "styled-components";
import toonflixlogo from "../../assets/images/toonflix.png";
import OtpInput from 'react-otp-input';
import ClipLoader from "react-spinners/ClipLoader"
import { GetRequest, QueryParams } from "../../utils/utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import Cookies from "js-cookie";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [ otp, setOtp ] = useState('');

  const nav = useNavigate();
  // const [ cookie, setCookie ] = useCookies(["msisdn"]);
  async function votp(e) {
      e.preventDefault();
      if(otp.length < 4) {
        toast.info('Enter PIN');
        return;
      }
      const q =  QueryParams();

      const msisdn =  q.get("msisdn") || localStorage.getItem("iq_toonflix_msisdn");
      const ext_ref = q.get("ext_ref") || localStorage.getItem("iq_toonflix_ext_ref");
      const uuid = q.get("uuid") || localStorage.getItem("iq_toonflix_uuid");

      const query = `msisdn=${
                       msisdn
                    }&otp=${otp}&ext_ref=${
                       ext_ref
                    }&uuid=${
                       uuid  
                    }&operator=${
                       "Zain"
                    }`;
      try {
        setLoader(true);
        const { ex, data } = await GetRequest({ action: "votp", query });
        setLoader(false);
        // console.log(data, '=== V-otp Data ===');
        if(ex) {
          // console.log(ex)
          toast.error("Internal server error");
          return;
        }
        if(data) {
          const i = data.data.result === 0 ? "error" : "info";
          toast[i](data.data.description);

          if(data.data.result === 1) {
            // setTimeout(() => {
              nav(`/success?msisdn=${
                msisdn
              }&uuid=${
                 uuid
              }&ext_ref=${
                 ext_ref
              }`);
              return;
            // }, 4000);
          } else if (data.data.result === 2){
            /** USER ALREADY EXIST */
            Cookies.set('iq-toonflix-msisdn', msisdn, { expires: 1 });
            nav('/redirect');
            return;
          } else {
            setOtp("");
          }
          return;
        };
      } catch (ex) {
        console.log(ex);
        toast.error("Network ex");
        return;
      }
  }
  // console.log("loader ", loader);
  return (  
    <HomeContainer>
        <Box>
            <ImageContainer>
                <img src={toonflixlogo}  alt="ToonFlix"/>
            </ImageContainer>
            <Heading>
                {"إشترك الآن في خدمة Toonflix"}
            </Heading>
            <Heading>
                {"أدخل الرقم السري"}
            </Heading>
            <OtpInput 
                inputStyle={{
                    height: '40px',
                    width: '40px',
                    border: "none",
                    outline: "none",
                    borderRadius: "4px",
                    gap: "5px",
                }}
                inputType="number"
                numInputs={5}
                value={otp}
                onChange={(o) => setOtp(o)}
                renderSeparator={<span style={{ color: "white"}}>-</span>}
                renderInput={(props) => <input {...props} />}
            />
            <Button 
            onClick={votp}
            disabled={loader}>
                { loader ? (
                    <ClipLoader
                     size={20}
                     color="white"
                     />
                ) : "تأكيد" }
            </Button>
        </Box>
    </HomeContainer>
  );
};

export default Login;

const HomeContainer = styled.div`

  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  /* background-color: #fff; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  backdrop-filter: rgba(0,0,0,0.1);
  background-blend-mode: overlay;
  box-sizing: border-box;
  padding: 25px;
  @media screen and (max-width: 768px) {
      padding: 25px 0 0;
  }
`;

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    flex-direction: column;
    /* gap: 25px;
     */
    /* background-color: whitesmoke; */
`;

const ImageContainer = styled.div`
    margin-bottom: 15px;
    img {
        width: 400px;
        height: 70px;
    }
    @media screen and (max-width: 768px) {    
        img {
            width: 300px;
        }
    }
`;

const Heading = styled.h1`
    color: #fff;
    /* margin-top: 2px; */
    font-weight: 800;
    @media screen and (max-width: 768px) {    
        font-size: 18px;
    }
`;

const Button = styled.button`
    margin: 20px 0 20px;
    width: 300px;
    padding: 6px;
    color: white;
    background-color: #007991;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    align-items: center;
`;
