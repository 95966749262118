import axios from "axios";

export const QueryParams = () => {
    return new URLSearchParams(window.location.search);
}

export const PostRequest = async ({ action, subscription}) => {
    try {
        const resp = await axios.post(`/api/${action}`, {...subscription});
        const data = await resp.data.data;
        return {ex: null, data};

    } catch (ex) {
        return { ex , data: null }
    }
};

export const GetRequest = async ({ action, query}) => {
    try {
        const resp = await axios.get(`/api/${action}?${query}`);
        const data = await resp.data;
        // console.log(data, "CHECK SUB DATA")
        return {ex: null, data};

    } catch (ex) {
        return { ex , data: null }
    }
};