import styled from 'styled-components';
import toonflixlogo from "../../assets/images/toonflix.png";
import React from 'react';


const ErrorPage = () => {

  return (

    <React.Fragment>
    <StyledPacks>
        <div style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop : "-200px"
        }} >

            <img src={toonflixlogo} alt="Tonnflix" style={{
                width: "250px",
                marginBottom: "20px",
            }}/>
            <div> 
            <h3>
عذرا لم يتم الاشتراك ،للاشتراك في خدمة Toonflix
ارسل TF الى 3368
            </h3>
            </div>
            <h3>
            كلفة الإشتراك لمشتركي زين240 دينار للرسالة المستلمة. يمكنك إلغاء الإشتراك بإرسال T2 إلى 3368 
عند اشتراكك في خدمة ToonFlix تحصل على يوم مجانا
            </h3>
        </div>
    </StyledPacks>
    </React.Fragment>
  )
}


export default ErrorPage;


const StyledPacks = styled.div`
/* position: absolute; */
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
/* margin: 25px auto; */
background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
 
h3{
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    // font-weight: 300;
    direction:rtl;
}
.select-here {
    width: 300px;
    color: #6c5b7c;
    text-align: center;
    margin-bottom: 10px;
}
.link{
    text-align: center;
    color: white;
    border: 0;
    background-color: #c06c84;
    padding: 10px;
    border-radius: 10px;
    /* width: 150px; */
    cursor: pointer;
    direction:rtl;
}
.link:hover{
    background-color: black;
}
@media screen and (max-width: 600px){
    h3 {
        margin: 5px;
        padding: 5px;
        text-align: center;
        font-size: 10px;
    }
}

`