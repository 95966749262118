import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";
import Confirm from "../../containers/Confirm/Confirm";
import Layout from "../Layout/Layout";
// import Validation from "../Validation/Validation";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner";
import ErrorPage from "../../containers/ErrorPage/ErrorPage";
import VideoDescription from "../../containers/VideoDescription/VideoDescription";
// import Integrated from "../../containers/Intigrated/Integrated";
import SuccessPage from "../../containers/Success/Success";
import HomePage from "../../containers/HomePage/HomePage";
import Cookies from "js-cookie";
import { GetRequest, QueryParams } from "../../utils/utils";

const DOIRedirect = React.lazy(() =>
  import("../../containers/DOIRedirect/DOIRedirect")
); // Lazy-loaded

const Navigations = () => {

  const nav = useNavigate();
  const location = useLocation();
  const q = QueryParams();

  useEffect(() => {
    const msisdn = localStorage.getItem("iq_toonflix_msisdn");
    const cookie_msisdn = Cookies.get("iq-toonflix-msisdn");
    
    if(cookie_msisdn && (cookie_msisdn !== null || cookie_msisdn !== "null")) {
      console.log("/****** COOKIE *******/");
      nav('/redirect');
      return;
    } else if (
        location.pathname.includes("confirm") || 
        location.pathname.includes("success")
      ){
      console.log("/***** WAIT *****/");
      return;
    } else if (msisdn && (msisdn !== null || msisdn !== "null")) {
      console.log("/******** NO COOKIE *********/");
      ChekUserValidty({ msisdn });
      return;
    } else {
      console.log('/***** DONT PEEK ******');
      if(window.location.pathname.includes("redirect")) {
        nav("/")
        return;
      }
    }
  }, []);

  const ChekUserValidty = async ({ msisdn, ext_ref }) => {

    const query = `msisdn=${msisdn}`;
    localStorage.setItem("iq_toonflix_ext_ref", ext_ref);

    const { ex, data } = await GetRequest({ action: "check-sub", query });
    if(ex) {
      console.log(ex);
      return;
    }
    console.log("=== CHECK SUB DATA ===")
    if(data) {
       if(data.result === 1) {
          /**USER EXIST */
          Cookies.set("iq-toonflix-msisdn", msisdn, { expires: 1 });
          nav("/redirect");
          return;

       } else {
          /**USER DONT EXIST */
          if(location.pathname.includes("redirect")) {
            nav("/");
          }
          return;
       }
    } else {
      /**SERVER ERROR */
    }
  };
  return (
    <React.Suspense
      fallback={
        <SuspenseStyled>
          <Spinner />
        </SuspenseStyled>
      }>
      <CookiesProvider>
        <Layout>
          <Routes>
             <Route path="/redirect" element={<DOIRedirect />} />
             <Route path="/" element={<HomePage />} />
             <Route path="/confirm" element={<Confirm />} />
             <Route path="/video/:id" element={<VideoDescription />} />
             {/* <Route path="/integrated" element={ <Integrated /> } /> */}
             <Route path="/failed" element={<ErrorPage />} />
             <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </Layout>
      </CookiesProvider>
    </React.Suspense>
  );
};

export default Navigations;

const SuspenseStyled = styled.div`
  background-image: linear-gradient(to right, #141e30, #243b55);
  /* background-color: yellow; */
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
