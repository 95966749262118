import React, { useEffect, useReducer } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

import UserContext from "./UserContext";

const initState = {
  status: "",

  loader: false,
  games: [],
  sideBar: true,
  category: [],
};
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN":
      // console.log(action.payload);
      return {
        ...state,
        status: action.payload,
      };
    case "ADD_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };
    case "ADD_GAMES":
      return {
        ...state,
        games: action.payload,
      };
    case "ADD_TEASERS":
      return {
        ...state,
        teasers: action.payload,
      };

    case "ADD_OPERATOR":
      return {
        ...state,
        operator: action.payload,
      };

    case "UPDATE_LOADER":
      return {
        ...state,
        loader: action.payload,
      };

    case "UPDATE_USER":
      const { username, jsonToken } = action.payload;
      // console.log("USERNAME IS ", state);
      // console.log("ACTION PAYLOD ", action.payload);
      return {
        ...state,
        userName: action.payload.username,
        jsonToken: action.payload.jsonToken,
        partnerName: action.payload.partnerName,
      };

    case "DELETE_TEASER":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        teasers: state.teasers.filter((item) => item.id !== action.payload),
      };
    case "DELETE_PRODUCT":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        products: state.products.filter((item) => item.id !== action.payload),
      };
    case "DELETE_SERVICES":
      // console.log(" ACTION PAYLOD ", action.payload);
      return {
        ...state,
        services: state.services.filter((item) => item.id !== action.payload),
      };
    case "UPDATE_CATEGORY":
      return {
        ...state,
        category: [...state.category, action.payload],
      };

    case "  ":
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case "DELETE_CATEGORY":
      return {
        ...state,
        category: state.category.filter((item) => item.id !== action.payload),
      };

    case "EDIT_CATEGORY":
      // console.log(" action payload ", action.payload);
      return {
        ...state,
        category: state.category.map((item) =>
          item.id === action.payload.id
            ? { ...item, name: action.payload.categeoryName }
            : item
        ),
      };
    case "FLUSH_STATE":
      return {
        ...initState,
      };

    case "SIDEBAROPEN":
      return {
        ...state,
        sideBar: !state.sideBar,
      };

    default:
      return state;
  }
};

const UserState = (props) => {
  useEffect(() => {
    // console.log("hello");

    const fetchGames = async () => {
      try {
        const response = await axios.get("/fetch-product");
        // console.log("response ", response.data);
        dispatch({ type: "ADD_GAMES", payload: response.data.videos });
        dispatch({ type: "ADD_CATEGORY", payload: response.data.cat });
      } catch (err) {
        console.log(err);
      }
    };
    fetchGames();

  }, []);

  const [state, dispatch] = useReducer(reducer, initState);
  //   console.log(state);
  const updateUser = (username, jsonToken, partnerName) => {
    dispatch({
      type: "UPDATE_USER",
      payload: { username, jsonToken, partnerName },
    });
  };

  const updateToken = (status) => {
    // console.log(status);
    // console.log("username, jsonToken ", jsonToken);

    // if (username !== "")
    dispatch({
      type: "UPDATE_TOKEN",
      payload: status,
    });
  };

  const removeToken = () => {
    window.localStorage.removeItem("token");
    removeCookie("user");

    dispatch({
      type: "FLUSH_STATE",
    });
  };

  const sideBarOpen = () => {
    dispatch({
      type: "SIDEBAROPEN",
    });
  };

  const addCategoryItems = (categoryItem) => {
    dispatch({ type: "ADD_CATEGORY", payload: categoryItem });

    // _.set(initState, "category", categoryItem);
  };

  const addProductsItems = (items) => {
    dispatch({ type: "ADD_GAMES", payload: items });
  };

  const updateLoader = (loader) => {
    dispatch({ type: "UPDATE_LOADER", payload: loader });
  };

  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  return (
    <UserContext.Provider
      value={{
        state,
        updateUser,
        updateToken,
        updateLoader,
        removeToken,
        addCategoryItems,
        addProductsItems,
        sideBarOpen,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
