import React from "react";
import ReactDOM from "react-dom/client";
// import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Navigations from "./components/Navigations/Navigation";
import { GlobalStyles } from "./components/GlobalStyled/GlobalStyled";
import UserState from "./components/context/UserState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <UserState>
      <GlobalStyles />
      <Navigations />
    </UserState>
    <App />
  </Router>
  //  </React.StrictMode>
);
